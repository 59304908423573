import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import { Prompt } from 'react-router'

import { Input, Button, IconButton, Dropdown, Switch, Snackbar, Tooltip, Checkbox, Tabs, Tab, ProgressBar } from 'react-toolbox';
import { List, ListItem, ListSubHeader, ListDivider, ListCheckbox } from 'react-toolbox/lib/list';
import Dialog from 'react-toolbox/lib/dialog';
import Autocomplete from 'react-toolbox/lib/autocomplete';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

import UdpStreamsEditor from './UdpStreamsEditor.js'

import Loader from 'react-loading-animation';

import { round } from './utils.js';

import { I18n } from 'react-i18next';

import i18n from './i18n'

import 'whatwg-fetch';

import styles from '../style/config.scss';

const TooltipInput = Tooltip(Input);
const TooltipCheckbox = Tooltip(Checkbox);
const TooltippedDiv = Tooltip(props => <div {...props} />);
const TooltipIcon = Tooltip(IconButton);

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

class Config extends React.Component {

  constructor(props) {
    super(props)

    this.purposes = [
      "purpose_hobby",
      "purpose_aftershock",
      "purpose_campaign",
      "purpose_edu",
      "purpose_glaciers",
      "purpose_micro",
      "purpose_monitor",
      "purpose_research",
      "purpose_observatory",
      "purpose_structural",
      "purpose_vulcano",
      "purpose_other"
    ]

    this.boomPurposes = [
      'purpose_geological',
      'purpose_natural',
      'purpose_weather',
      'purpose_cultural',
      'purpose_manmade',
      'purpose_astronomical',
      'purpose_unknown'
    ]
    
    this.blockNavigation = true
    this.staticMap = null
    this.configPreloaded = false
    
  }

  componentDidMount = () => {
    // if('initialConfig' in this.props) {
    //   console.log('preloading config: ', this.props.initialConfig)
    //   this.loadConfig(this.props.initialConfig);
    //   this.configPreloaded = true
    // } 

    fetch('/api/wifi', {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, hasWiFi: data.wifi});
    });

    console.log('config: ', this.props.intialConfig);
    
    if(!this.configPreloaded) {
      console.log('fetching config...');
      fetch('/api/config', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }).then(function(response) {
        return response.json();
      }).then((data) => {
        this.loadConfig(data);
      });
    }

    this.loadNetworkConfig();
    this.loadWifiNetworkConfig();
    this.loadUdpStreams();
    this.loadTooltips();

    // this.props.router.setRouteLeaveHook(this.props.route, (route) => {
    //   if(this.blockNavigation && this.state.dirtyConfig) {
    //     this.setState({...this.state, unsavedActive: true, cancelledRoute: route.pathname});
    //     return false;
    //   }
    //   return true;
    // });

    i18n.on('languageChanged', lng => {
      this.purgePurposes()
    })
  }

  state = {
    index: 0,
    networkIndex: 0,
    password: '',
    dataSnackActive: false,
    snackActive: false,
    wifiSnackActive: false,
    wifiFailSnackActive: false,
    validWifiSnackActive: false,
    restartDialogActive: false,
    defaultRestartActive: false,
    licenseActive: false,
    snackMessage: '',
    dialogActive: false,
    purposeDialogActive: false,
    warningActive: false,
    unsavedActive: false,
    validationActive: false,
    currentZoom: 2,
    currentLocation: { lat: 0, lng: 0 },
    pickedLocation: false,
    hasWiFi: false,
    validWiFi: false,
    wifiDialog: false,
    dirtyConfig: false,
    cancelledRoute: '',
    requestSent: false,
    savingWiFi: false,
    wifiNetworks: [],
    hint: '',
    config: {
      streams: [],
      boomFilter: 1,
      network: {
        static: false,
        wifi: false
      },
      dataSharing: {
        licenseAgreed: false,
        boomPurpose: '',
        purpose: '',
        lat: 0.0,
        lon: 0.0
      },
      other: { heliscaling: 1.0, archkeep: 7 },
      ntpServers: [],
      enableStatic: false,
      enableStaticWifi: false,
    },
    tooltips: {},
    gestureOpts: {
      duration: 5000
    }
  }

  loadConfig = (initConfig) => { 
    var hasLocation = false;
    var location = this.state.currentLocation;
    var zoomLevel = this.state.currentZoom;
    console.log('initConfig lat: ', initConfig.dataSharing.lat)
    console.log('initConfig lon: ', initConfig.dataSharing.lon)
    console.log('state.config lat: ', this.state.config.dataSharing.lat)
    console.log('state.config lon: ', this.state.config.dataSharing.lon)
    var loadedConfig = Object.assign(this.state.config, initConfig);
    
    if(initConfig.dataSharing.lat && initConfig.dataSharing.lon) {
      hasLocation = true;
      location.lat = round(parseFloat(initConfig.dataSharing.lat), 4);
      location.lng = round(parseFloat(initConfig.dataSharing.lon), 4);
      zoomLevel = 9;
    }

    loadedConfig.dataSharing.floor = parseInt(loadedConfig.dataSharing.floor);
    if (initConfig.dataSharing.lat) {
      loadedConfig.dataSharing.lat = round(parseFloat(initConfig.dataSharing.lat), 4)
    }
    if (initConfig.dataSharing.lon) {
      loadedConfig.dataSharing.lon = round(parseFloat(initConfig.dataSharing.lon), 4)
    }
    this.createPurposeIfNecessary();

    console.log('loadedConfig lat: ', loadedConfig.dataSharing.lat)
    console.log('loadedConfig lat: ', loadedConfig.dataSharing.lon)
    if(this.props.deviceType.includes('RBOOM.Bm')) {
      loadedConfig.dataSharing.floor = 0
      loadedConfig.dataSharing.totalFloors = 0
    }
    
    this.setState({...this.state,
                   currentZoom: zoomLevel,
                   currentLocation: location,
                   pickedLocation: hasLocation,
                   dirtyConfig: false,
                   config: loadedConfig,
                   validWiFi: loadedConfig.network.ssid !== ''},
                  () => {
                    this.purgePurposes()
                  });
  }

  loadNetworkConfig = () => {
    fetch('/api/network/eth0', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = this.state.config;
      config.enableStatic = !data.dhcp;
      if (!data.dhcp) {
        config.staticIP = data.ip;
      }
      config.enableDNS = data.static_dns
      config.dnsServer = data.dns
      this.setState({...this.state, config: config})
    })
  }

  loadWifiNetworkConfig = () => {
    fetch('/api/network/wlan0', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = this.state.config;
      config.enableStaticWifi = !data.dhcp;
      if (!data.dhcp) {
        config.staticIPWifi = data.ip;
      }
      config.enableDNSWifi = data.static_dns
      config.dnsServerWifi = data.dns
      config.network.wifi = data.wifi
      if (data.wifi) {
        this.getWifiNetworks()
      }
      this.setState({...this.state, config: config})
    })
  }

  loadUdpStreams = () => {
    fetch('/api/udpstreams', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = this.state.config;
      config.streams = data.streams;
      this.setState({...this.state, config: config})
    })
  }

  loadTooltips = () => {
    fetch('/api/tooltips', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, tooltips: data});
    });
  }

  loadWifi = (on) => {
    var loadUrl = '/api/enable-wifi/' + 'off'
    if (on) {
      loadUrl = '/api/enable-wifi/' + 'on'
    }
    
    fetch(loadUrl, {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then((data) => {
      if (data.success) {
        this.getWifiNetworks()
      } else {
        var config = this.state.config
        config.network.wifi = false
        this.setState({
          ...this.state,
          wifiFailSnackActive: true,
          config: config
        })
      }
    })
  }

  loadHint = () => {
    fetch("/api/hint", {
      method: 'GET'
    })
      .then((response) => { return response.json();})
      .then((data) => {
        this.setState({...this.state, hint: data.hint});
      });
  }

  getWifiNetworks = () => {
    fetch('/api/wifi-networks', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = this.state.config;
      config.network.ssid = data["current"]
      if (config.network.ssid === '') {
        config.network.password = ''
      }
      this.setState({...this.state, wifiNetworks: data["networks"], config: config})
    })
  }

  createPurposeIfNecessary = () => {
    if (this.state.config.dataSharing.purpose) {
      if (!this.purposes.map(p => i18n.t(p)).some(p => p === this.state.config.dataSharing.purpose)) {
        this.purposes = this.purposes.filter(p => p.startsWith('purpose_'))
        this.purposes.push(this.state.config.dataSharing.purpose);
      }
    }
  }

  purgePurposes = () => {
    var customPurposes = this.purposes.filter(p => !p.startsWith('purpose_'))
    customPurposes.forEach(cp => {
      if(this.purposes
             .filter(p => p.startsWith('purpose_'))
             .map(p => i18n.t(p))
             .some(p => p === cp)) {
        this.purposes.splice(this.purposes.indexOf(cp), 1)
      }
    })

    var langs = ['en', 'fr', 'es', 'nl']
    i18n.loadLanguages(langs, (s, e) => {
      langs.forEach(lang => {
        var fixedLang = i18n.getFixedT(lang)
        this.purposes.filter(p => p.startsWith('purpose_')).forEach(purp => {
          if (this.state.config.dataSharing.purpose === fixedLang(purp)) {
            var spliceIndex = this.purposes.indexOf(this.state.config.dataSharing.purpose)
            if (spliceIndex > 0) {
              this.purposes.splice(spliceIndex, 1)
            }
            var newConfig = this.state.config
            newConfig.dataSharing.purpose = i18n.t(purp)
            this.setState({...this.state, config: newConfig})
          }
        })
      })
    })
  }

  handleSharingChange = (name, value) => {
    var config = this.state.config;
    
    if((name == 'elevation' || name == 'totalFloors' || name == 'floor') && value) {
      var floatVal = parseFloat(value);
      if(!isNaN(floatVal))
        config.dataSharing[name] = floatVal;
    } else if ((name == 'lon' || name == 'lat') && value) {
      config.dataSharing[name] = round(parseFloat(value), 4);
    } else {
      config.dataSharing[name] = value;
    }

    var dsActive = this.state.dataSnackActive;
    var licenseActive = this.state.licenseActive;
    if(name == 'enabled' && value == true) {
      if (this.state.config.dataSharing.licenseAgreed) {
        config.dataSharing[name] = value;
        dsActive = true;
      } else {
        config.dataSharing[name] = false;
        licenseActive = true;
      }
    }

    this.setState({...this.state,
                   dataSnackActive: dsActive, licenseActive: licenseActive,
                   config: config, dirtyConfig: true})
  }

  handleNetworkChange = (name, value) => {
    var config = this.state.config;
    config.network[name] = value;

    if (name === 'wifi') {
      this.loadWifi(value)
      if (!value) { // clear ssid/password on disable of wifi
        config.network.password = ''
        config.network.ssid = ''
      }
    }
    
    this.updateConfig(config);
  }

  handleOtherChange = (name, value) => {
    var config = this.state.config;

    if(name == 'archkeep' && value) {
      var intVal = parseInt(value);
      if(!isNaN(intVal))
        config.other[name] = intVal;
    } else {
      config.other[name] = value;
    }
    
    this.updateConfig(config);
  }

  handlePurposeChange = (value) => {
    var config = this.state.config;
    config.dataSharing.purpose = value;
    
    if (value === i18n.t("purpose_other")) {
      var customOptions = this.purposes.filter(p => !p.startsWith("purpose_"))
      if (customOptions.length > 0) {
        var newConfig = this.state.config
        newConfig.dataSharing.purpose = customOptions[0]
        this.setState({...this.state, config: newConfig})
      }
      
      this.state.purposeDialogActive = true;
    }
    this.updateConfig(config);
  }

  handleNTPChange = (value) => {
    var config = this.state.config;
    var servers = value.split(',');
    config.ntpServers = servers;
    this.updateConfig(config);
  }
  
  handleChange = (name, value) => {
    var config = this.state.config;
    config[name] = value;

    if (name === 'enableStatic' && value) {
      config['enableDNS'] = true;
    }
    
    this.updateConfig(config);
  }

  updateConfig = (config) => {
    this.setState({...this.state, config: config, dirtyConfig: true});
  }

  handleLocalChange = (name, value) => {
    this.setState({...this.state, [name]: value});
  }

  handleSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      snackActive: false
    });
  }

  handleWifiSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      wifiSnackActive: false
    });
  }

  handleWifiFailSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      wifiFailSnackActive: false
    });
  }

  handleValidWifiSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      validWifiSnackActive: false
    });
  }

  handleDataSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      dataSnackActive: false
    });
  }

  handleTabChange = (index) => {
    this.setState({...this.state, index: index});
  }

  handleNetworkTabChange = (index) => {
    this.setState({...this.state, networkIndex: index});
  }
  
  handleToggle = () => {
    this.setState({...this.state, dialogActive: !this.state.dialogActive});
  }

  handleWarningToggle = () => {
    this.setState({...this.state, warningActive: !this.state.warningActive});
  }

  handleUnsavedToggle = (discard) => {
    this.setState({...this.state, unsavedActive: !this.state.unsavedActive});
    if(discard) {
      this.blockNavigation = false;
      this.props.history.push(this.state.cancelledRoute);
    }
  }

  handleRestartDialogToggle = () => {
    this.setState({...this.state, restartDialogActive: !this.state.restartDialogActive});
  }

  handlePurposeDialogToggle = () => {
    this.createPurposeIfNecessary();
    this.setState({...this.state, purposeDialogActive: !this.state.purposeDialogActive});
  }
  
  handleValidationToggle = () => {
    this.setState({...this.state, validationActive: !this.state.validationActive});
  }

  handleLicenseToggle = (agree) => {
    if (agree) {
      var config = this.state.config;
      config.dataSharing.enabled = true;
      config.dataSharing.licenseAgreed = true;      
      this.setState({...this.state, config: config,
                     dataSnackActive: true, licenseActive: !this.state.licenseActive});
    } else {
      this.setState({...this.state, licenseActive: !this.state.licenseActive});
    }
  }
  
  handleKeyPress = (callback, ev) => {
    if (ev.key === 'Enter') {
      callback();
    }
  }

  onMapClicked = (map, e) => {
    var latlng = e.latlng.wrap();
    var config = this.state.config;
    config.dataSharing.lat = round(parseFloat(latlng.lat), 4);
    config.dataSharing.lon = round(parseFloat(latlng.lng), 4);

    if(latlng.lat < 60 && latlng.lat > -58)
      {
        this.getElevation(latlng.lat, latlng.lng);
      } else {
        var elevConfig = this.state.config;
        config.dataSharing.elevation = '';
        this.setState({...this.state, warningActive: true, config: elevConfig});
      }

    var zoomLevel = this.staticMap.leafletElement.getZoom();

    this.setState({...this.state,
                   currentZoom: zoomLevel,
                   currentLocation: latlng,
                   pickedLocation: true,
                   config: config });
  }

  onStreamsUpdated = (streams) => {
    var config = this.state.config;
    config.streams = streams;
    this.setState({...this.state, config: config })
    this.saveUdp()
  }

  getElevation = (lat, long) => {
    fetch(`http://open.mapquestapi.com/elevation/v1/profile?key=SjoMwaplANZOBK5aeC2IGGUQBWjtcIuM&shapeFormat=raw&latLngCollection=${lat},${long}`, {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = this.state.config;
      config.dataSharing.elevation = data.elevationProfile[0].height;
      this.setState({...this.state, config: config });
    });
  }

  testDefaultPassword = () => {
    return fetch("/api/pw-test", {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": "shakeme"
      })
    })
    .then(function(response) {
      return response.json()
    })
    .then((data) => {
      return data.success
    });
  }

  submit = () => {
    var missingFields = [];
    var sharingValid = true

    if(this.state.config.dataSharing.enabled) {
      if(!this.state.config.dataSharing.firstName)
        missingFields.push(i18n.t("config_ds_fname") + " " + i18n.t("validation_required"));

      if(!this.state.config.dataSharing.mailAddress)
        missingFields.push(i18n.t("config_ds_email") + " " + i18n.t("validation_required"));

      const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      if (!emailRegex.test(this.state.config.dataSharing.mailAddress)) {
        missingFields.push(i18n.t("config_ds_email") + " " + i18n.t("validation_error"));
      }

      if(!this.state.config.dataSharing.lat || !this.state.config.dataSharing.lon)
        missingFields.push('Geo location' + " " + i18n.t("validation_required"));

      if(this.state.config.dataSharing.lat && (this.state.config.dataSharing.lat > 90 || this.state.config.dataSharing.lat < -90))
        missingFields.push(i18n.t('home_lat') + " " + i18n.t("validation_between_lat"))

      if(this.state.config.dataSharing.lon && (this.state.config.dataSharing.lon > 180 || this.state.config.dataSharing.lon < -180))
        missingFields.push(i18n.t('home_lon') + " " + i18n.t("validation_between_lon"))

      if(!this.state.config.dataSharing.elevation)
        missingFields.push( i18n.t("home_elev") + " " + i18n.t("validation_required"));
      
      if(isNaN(this.state.config.dataSharing.floor))
        missingFields.push(i18n.t("config_ds_floor") + " " + i18n.t("validation_required"));

      if(!this.props.deviceType.includes('RBOOM.Bm') && this.state.config.dataSharing.totalFloors < 1)
        missingFields.push( i18n.t("config_ds_totalfloors") + " " + i18n.t("validation_least_1"));
      else if (!this.props.deviceType.includes('RBOOM.Bm') && this.state.config.dataSharing.totalFloors <= this.state.config.dataSharing.floor)
        missingFields.push( i18n.t("config_ds_totalfloors") + " " + i18n.t("validation_gt") + " " + i18n.t("config_ds_floor"));

      if (missingFields.length > 0) {
        sharingValid = false
      }
      //console.log("floor: ", this.state.config.dataSharing.floor);
      //console.log("totalFloors: ", this.state.config.dataSharing.totalFloors);
    }

    if(this.state.config.other.archkeep < 1 || this.state.config.other.archkeep > 360)
      missingFields.push(i18n.t("config_adv_waveform") + " " + i18n.t("validation_between"));

    if(missingFields.length > 0) {
      var tabIndex = this.state.index
      if (!sharingValid) {
        tabIndex = 0
      }
      this.setState({...this.state, index: tabIndex, missingFields: missingFields, validationActive: true});
    } else {
      this.setState({...this.state, savingWiFi: false})
      this.saveConfig(true)
      .catch(err => {
        console.log('error while doing default password: ', err)
        this.setState({...this.state, savingWiFi: false, missingFields: missingFields, requestSent: false, restartDialogActive: true, defaultRestartActive: false});
      })
    }
  }

  submitNetwork = () => {
    var missingFields = [];
    if(this.state.hasWiFi && this.state.config.network.wifi) {
      if(!this.state.config.network.ssid)
        missingFields.push(i18n.t("config_wifi_ssid"));

      // if(!this.state.config.network.password)
      //   missingFields.push(i18n.t("config_wifi_pw"));
    }

    if(this.state.config.enableStatic && !this.state.config.staticIP) {
      missingFields.push(i18n.t("config_net_static_ip"));
    }
    if(this.state.config.enableStatic && !this.state.config.dnsServer) {
      missingFields.push(i18n.t("config_net_dns_server"));
    }

    if(this.state.config.enableStaticWifi && !this.state.config.staticIPWifi) {
      missingFields.push("WiFi " + i18n.t("config_net_static_ip"));
    }
    if(this.state.config.enableStaticWifi && !this.state.config.dnsServerWifi) {
      missingFields.push("WiFi " + i18n.t("config_net_dns_server"));
    }

    if(missingFields.length > 0) {
      this.setState({...this.state, missingFields: missingFields, validationActive: true});
    } else {
      this.setState({...this.state, savingWiFi: true}, () => {
        this.saveConfig(true)
        .catch(err => {
          console.log('error while doing default password: ', err)
          this.setState({...this.state, savingWiFi: true, missingFields: missingFields, requestSent: false, restartDialogActive: true, defaultRestartActive: false});
        })
      })
    }
  }

  saveConfig = (tryDefault = false) => {
    console.log('saving config, default: ', tryDefault)
    this.setState({...this.state, requestSent: true, defaultRestartActive: tryDefault});
    if (tryDefault) {
      return this.testDefaultPassword()
        .then(succeeded => {
          if (!succeeded) {
            console.log('default password failed')
            throw 'default auth fail'
          } else {
            return this.executeSaveConfig(true)
          }
        })
    } else {
      return this.executeSaveConfig()
    }
  }

  executeSaveConfig = (tryDefault = false) => {
    console.log('saving config: ', this.state.config)
    if (this.state.savingWiFi) {
      var networkObj = {
        network: this.state.config.network,
        enableStatic: this.state.config.enableStatic,
        enableStaticWifi: this.state.config.enableStaticWifi,
        staticIP: this.state.config.staticIP,
        dnsServer: this.state.config.dnsServer,
        staticIPWifi: this.state.config.staticIPWifi,
        dnsServerWifi: this.state.config.dnsServerWifi,
      }

      return fetch('/api/network-config', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "config": networkObj
        })
      }).then((response) => {
        this.setState({...this.state, dirtyConfig: false});
        return this.callReboot(tryDefault);
      });
    } else {
      var configCopy = this.state.config
      
      var langs = ['en', 'fr', 'es', 'nl']
      i18n.loadLanguages(langs, (s, e) => {
        var english = i18n.getFixedT('en')
        langs.forEach(lang => {
          var fixedLang = i18n.getFixedT(lang)
          this.purposes.filter(p => p.startsWith('purpose_')).forEach(purp => {
            if (configCopy.dataSharing.purpose === fixedLang(purp)) {
              configCopy.dataSharing.purpose = english(purp)
            }
          })
        })
      })
      
      return fetch("/api/config", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "config": configCopy
        })
      }).then((response) => {
        this.setState({...this.state, dirtyConfig: false});
        this.props.updateConfig();
        
        return this.callRestart(tryDefault);      
      });
    }
  }

  saveUdp = () => {
    fetch("/api/udpstreams", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "streams": this.state.config.streams
      })
    }).then((response) => {
      this.setState({...this.state, snackActive: true, snackMessage: i18n.t("config_udp_saved")})
    })
  }

  checkWiFi = () => {
    window.testingWiFi = true;
    this.setState({...this.state, wifiDialog: true });
    
    fetch("/api/test-wifi", {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        'ssid': this.state.config.network.ssid,
        'password': this.state.config.network.password
      })
    })
      .catch(_ => console.log('Expected. Checking connection'))
      .then(_ => {
        this.waitForWiFiResult();
      });
    
  }

  waitForWiFiResult = () => {
    return fetch("/api/check-wifi", {
      method: 'GET',
      credentials: 'include'
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if(data.status == "none") {
          return this.waitForWiFiResult()
        } else {
          window.testingWiFi = false;
          this.setState({...this.state, wifiDialog: false });
          if(data.status == "working") {
            this.setState({...this.state, validWiFi: true, validWifiSnackActive: true });
          } else {
            this.setState({...this.state, wifiSnackActive: true });
          }
        }
      })
      .catch(_ => this.waitForWiFiResult())
  }

  callReboot = (tryDefault = false) => {
    return fetch("/api/reboot", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": tryDefault ? "shakeme" : this.state.password
      })
    })
    .catch(function(error) {
      console.log("error: ", error);
      this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("unsp_error")});
    })
    .then((response) => { return response.json(); })
    .then((data) => {
      if(data.success) {
        this.props.setRPiState(1);
        this.setState({...this.state, restartDialogActive: false, password: '', requestSent: false, defaultRestartActive: false,
                        snackActive: true, snackMessage: i18n.t("config_saved") },
                      () => this.props.history.replace({ pathname: "/", state: { rebooting: true }}));
      } else {
        if (!tryDefault) {
          this.setState({...this.state, requestSent: false, snackActive: true, defaultRestartActive: false,
                        snackMessage: i18n.t("auth_fail")});
        } else {
          throw 'Default auth failed'
        }
      }
    })
  };

  callRestart = (tryDefault = false) => {
    return fetch("/api/restart", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": tryDefault ? "shakeme" : this.state.password
      })
    })
    .catch(function(error) {
      console.log("error: ", error);
      this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("unsp_error")});
    })
    .then((response) => { return response.json(); })
    .then((data) => {
      if(data.success) {
        this.setState({...this.state, restartDialogActive: false, password: '', requestSent: false, defaultRestartActive: false,
                        snackActive: true, snackMessage: i18n.t("config_saved2") });
        this.props.history.push("/");
      } else {
        if (!tryDefault) {
          this.setState({...this.state, requestSent: false, snackActive: true, defaultRestartActive: false,
                        snackMessage: i18n.t("auth_fail")});
        } else {
          console.log('default failed')
          throw 'Default auth failed'
        }
      }
    })
  };

  onMapLoad = (map) => {
    console.log('map loaded: ', map)
    /* this.refs.staticmap.style = {height:"501px", width:"100%",position:"relative"}
     * this.refs.staticmap.leafletElement.invalidateSize()
     * this.refs.staticmap.leafletElement.panTo([this.state.config.dataSharing.lat, this.state.config.dataSharing.lat])*/
  }

  customItem (item) {
    const containerStyle = {
      display: 'flex',
      flexDirection: 'row',
      color: item.fg,
      fontSize: '1.6rem'  
    };

    return (
      <div style={containerStyle}>
        <span>{item.label}</span>
      </div>
    );
  }

  
  render() {
    var noneStyle = {
      display: "none"
    };

    const hwVersions = [
      { value: "V4", label: "V4"}
    ];

    const geophones = [
      { value: "Factory", label: i18n.t("yes")},
      { value: "Other", label: i18n.t("no")}
    ];

    const boomfilters = [
      { value: 1, label: i18n.t("config_ds_boom_filter_1s"), fg: '#FF0000' },
      { value: 20, label: i18n.t("config_ds_boom_filter_20s"), fg: ' #FFFF00' }
    ];

    const dialogActions = [
      { label: i18n.t("done"), onClick: this.handleToggle }
    ];

    const purposeDialogActions = [
      { label: i18n.t("done"), onClick: this.handlePurposeDialogToggle }
    ];

    const warningActions = [
      { label: i18n.t("ok"), onClick: this.handleWarningToggle }
    ];

    const unsavedActions = [
      { label: i18n.t("yes"), onClick: this.handleUnsavedToggle.bind(this, true) },
      { label: i18n.t("no"), onClick: this.handleUnsavedToggle.bind(this, false) }
    ];

    const restartActions = [
      { label: i18n.t("ok"), onClick: this.saveConfig.bind(this, false) },
      { label: i18n.t("cancel"), onClick: this.handleRestartDialogToggle }
    ];

    const validationActions = [
      { label: i18n.t("ok"), onClick: this.handleValidationToggle }
    ];

    const licenseActions = [
      { label: i18n.t("yes"), onClick: this.handleLicenseToggle.bind(this, true) },
      { label: i18n.t("no"), onClick: this.handleLicenseToggle.bind(this, false) }
    ]

    const purposes = this.purposes
                         .filter(p => p)
                         .map(purp => {
                           return {label: i18n.t(purp), value: i18n.t(purp) };
                         });

    const boomPurposes = this.boomPurposes
                             .filter(p => p)
                             .map(purp => {
                               return { label: i18n.t(purp), value: i18n.t(purp) };
                             });
    
    const heliscales = [
      { value: 0.1, label: '0.1' },
      { value: 0.2, label: '0.2' },
      { value: 0.3, label: '0.3' },
      { value: 0.4, label: '0.4' },
      { value: 0.5, label: '0.5' },
      { value: 0.6, label: '0.6' },
      { value: 0.7, label: '0.7' },
      { value: 0.8, label: '0.8' },
      { value: 0.9, label: '0.9' },
      { value: 1.0, label: '1.0' },
      { value: 1.1, label: '1.1' },
      { value: 1.2, label: '1.2' },
      { value: 1.3, label: '1.3' },
      { value: 1.4, label: '1.4' },
      { value: 1.5, label: '1.5' }
    ];

    var wifiNets = this.state.wifiNetworks.map(n => {
      return { value: n, label: n }
    });
    
    const missingFields = this.state.missingFields ? (
      <ul>
        {this.state.missingFields.map(f => (<li>{f}</li>))}
      </ul>
    ) : null;
    
    
    const marker = this.state.pickedLocation ? (
      <Marker position={[this.state.config.dataSharing.lat, this.state.config.dataSharing.lon]}>
        <Popup>
          <span>You selected this location.</span>
        </Popup>
      </Marker>
    ) : null;
    
    // currently not used, for future purposes
    const hwVersionItem = ( 
      <ListItem>
        <Dropdown
          auto
          required
          source={hwVersions}
          onChange={this.handleChange.bind(this, 'hwVersion')}
          label="Hardware version"
          value={this.state.config.hwVersion} />
      </ListItem>
    );

    return (
      <I18n ns="translations">
        {
        (t, { i18n }) => (         
        
        <section>
          <Tabs index={this.state.index} onChange={this.handleTabChange}
                className={styles.cleanTabs} theme={styles}>
            <Tab label={ t("config_general") }>  
              <List>
                <div>
                  { this.props.deviceType.includes('RBOOM') && (
                  <ListItem>
                    <Dropdown
                      required
                      className={styles.fixedDropdown} theme={styles}
                      source={boomfilters}
                      template={this.customItem}
                      onChange={this.handleChange.bind(this, 'boomFilter')}
                      label={ t("config_ds_boom_filter") }
                      value={this.state.config.boomFilter} />
                    <TooltipIcon icon="info"
                                  tooltipShowOnClick={true}
                                  tooltip={ t("tooltip_boomfilter") } />
                  </ListItem>
                  )}
                  
                  { !this.props.deviceType.includes('RBOOM.Bm') && (
                  <ListItem>
                    <Dropdown
                      required
                      source={geophones}
                      onChange={this.handleChange.bind(this, 'geophoneType')}
                      label={ t("config_ds_geophone") }
                      value={this.state.config.geophoneType} />
                    <TooltipIcon icon="info"
                                  tooltipShowOnClick={true}
                                  tooltip={ t("tooltip_geo") } />
                  </ListItem>
                  )}
                  
                  
                  { this.props.deviceType.includes('NEEDSTOCHANGETOBOOM') && (
                  <ListItem>
                    <Dropdown
                      auto={false}
                      label={ t("config_ds_boom_purpose") }
                      onChange={this.handleSharingChange.bind(this, 'boomPurpose')}
                      source={boomPurposes}
                      value={this.state.config.dataSharing.boomPurpose}
                    />
                    <TooltipIcon icon="info"
                                  target="_blank"
                                  href="https://manual.raspberryshake.org/boom.html#sources-of-infrasound-and-their-dominant-frequencies"
                                  tooltip={ t("tooltip_boompurpose") } />
                  </ListItem>
                  ) }
                  
                  { !this.props.deviceType.includes('RBOOM') && (
                  <ListItem>
                    <Dropdown
                      auto={false}
                      label={ t("config_ds_purpose") }
                      onChange={this.handlePurposeChange}
                      source={purposes}
                      value={this.state.config.dataSharing.purpose}
                    />
                    <TooltipIcon icon="info"
                                  tooltipShowOnClick={true}
                                  tooltip={ t("tooltip_purpose") } />
                  </ListItem>
                  )}
                </div>
                
                <ListItem>
                  <TooltipInput type='text' label={ t("config_ds_fname") } tooltip={this.state.tooltips.firstName}
                                value={this.state.config.dataSharing.firstName}
                                onChange={this.handleSharingChange.bind(this, 'firstName')} />
                </ListItem>
                <ListItem>
                  <TooltipInput type='text' label={ t("config_ds_lname") } tooltip={this.state.tooltips.lastName}
                                value={this.state.config.dataSharing.lastName}
                                onChange={this.handleSharingChange.bind(this, 'lastName')} />
                </ListItem>
                <ListItem>
                  <TooltipInput type='email' label={ t("config_ds_email") }
                                tooltip={ t("tooltip_email") }
                                value={this.state.config.dataSharing.mailAddress}
                                onChange={this.handleSharingChange.bind(this, 'mailAddress')} />
                </ListItem>

                <div id="map" className={styles.compactDiv} theme={styles}>
                  <ListSubHeader caption={ t("config_ds_setloc") }></ListSubHeader>
                  <TooltipIcon icon="info"
                                tooltipShowOnClick={true}
                                tooltip={ t("config_ds_setloc_tooltip") } />
                </div>
                <div >
                  <p>{ t("config_ds_setloc_note") }</p>
                </div>
                <Map center={[this.state.config.dataSharing.lat, this.state.config.dataSharing.lon]}
                      zoom={this.state.currentZoom}
                      style={{height:"500px", width:"50%", position:"relative", zIndex: "0", margin: "1em 0em"}}
                      zoomControl={true} scrollWheelZoom={false}
                      touchZoom={true} doubleClickZoom={true}
                      worldCopyJump={true} animate={true}
                      whenReady={this.onMapLoad}
                      onClick={this.onMapClicked.bind(this, this)}
                      ref={(map) => {this.staticMap = map} }
                      gestureHandling={true}
                      gestureHandlingOptions={this.state.gestureOpts}>
                  <TileLayer
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {marker}
                </Map>
                <ListItem>
                  <Input type='number' label={ t("home_lat") }
                          value={this.state.config.dataSharing.lat}
                          onChange={this.handleSharingChange.bind(this, 'lat')} />
                </ListItem>
                <ListItem>
                  <Input type='number' label={ t("home_lon") }
                          value={this.state.config.dataSharing.lon}
                          onChange={this.handleSharingChange.bind(this, 'lon')} />
                </ListItem>
                <ListItem>
                  <Input type='number' label={ t("config_ds_elev") }
                          value={this.state.config.dataSharing.elevation}
                          onChange={this.handleSharingChange.bind(this, 'elevation')} />
                </ListItem>
                <div style={this.props.deviceType.includes('RBOOM.Bm') ? noneStyle : null}>
                  <ListItem>
                    <TooltipInput type='number' label={ t("config_ds_floor") } tooltip={ t("tooltip_floor") }
                                  value={this.state.config.dataSharing.floor}
                                  onChange={this.handleSharingChange.bind(this, 'floor')} />
                  </ListItem>
                  <ListItem>
                    <Input type='number' label={ t("config_ds_totalfloors") } min="1"
                            value={this.state.config.dataSharing.totalFloors}
                            onChange={this.handleSharingChange.bind(this, 'totalFloors')} />
                  </ListItem>
                </div>
              </List>
              <div>
                <Button label={ this.state.config.dataSharing.enabled ? t("config_save_restart") : t("config_save") } onClick={this.submit} primary raised />
              </div>
            </Tab>
            
            <Tab label={ t("config_adv") }>
              <List className={styles.shrinkedList} theme={styles}>
                <ListSubHeader caption={ t("config_ds") } />
                <ListItem>
                  <TooltippedDiv tooltip={ t("tooltip_ds")}>
                    <Checkbox label={ t("config_ds_field") } 
                              checked={this.state.config.dataSharing.enabled}
                              onChange={this.handleSharingChange.bind(this, 'enabled')} />
                  </TooltippedDiv>
                </ListItem>
                <ListSubHeader caption={ t("config_adv_heli") }  />
                <ListItem>
                  <Dropdown auto
                            className={styles.shrinkedDropdown} theme={styles}
                            onChange={this.handleOtherChange.bind(this, 'heliscaling')} source={heliscales}
                            value={this.state.config.other.heliscaling} />
                </ListItem>
                <ListSubHeader caption={ t("config_adv_waveform") } theme={styles} />
                <ListItem>
                  <Input type='number' min="1" max="360"
                         className={styles.shrinkedInput} theme={styles}
                         onChange={this.handleOtherChange.bind(this, 'archkeep')}
                         value={this.state.config.other.archkeep} />
                  <TooltipIcon icon="info"
                               tooltipShowOnClick={true}
                               tooltip={ t("tooltip_waveform") }/>
                </ListItem>
              </List>
              <div>
                <Button label={ t("config_save_restart") } onClick={this.submit} primary raised />
              </div>
            </Tab>

            <Tab label={ t("config_network") }>
              <Tabs index={this.state.networkIndex} onChange={this.handleNetworkTabChange}
                    className={styles.cleanSubTabs} theme={styles}>
                <Tab label={ t("config_ethernet") } >
                  <List>
                    <ListItem>
                      <Checkbox label={ t("config_net_static")}
                                checked={this.state.config.enableStatic}
                                onChange={this.handleChange.bind(this, 'enableStatic')} />
                    </ListItem>
                    <ListItem>
                      <TooltipInput type='text' label={ t("config_net_static_ip") }
                                    tooltip={t("config_net_static_tooltip")}
                                    hint="10.0.0.10"
                                    className={styles.ipAddressInput} theme={styles}
                                    value={this.state.config.staticIP}
                                    disabled={!this.state.config.enableStatic}
                                    onChange={this.handleChange.bind(this, 'staticIP')} />
                    </ListItem>
                    <ListItem>
                      <TooltipInput type='text' label={ t("config_net_dns_server") }
                                    tooltip={t("config_net_dns_tooltip")}
                                    hint="8.8.8.8"
                                    className={styles.ipAddressInput} theme={styles}
                                    value={this.state.config.dnsServer}
                                    //disabled={!this.state.config.enableStatic}
                                    onChange={this.handleChange.bind(this, 'dnsServer')} />
                    </ListItem>
                  </List>
                </Tab>
                <Tab label={ t("config_wifi") } hidden={ !this.state.hasWiFi } >
                  <List>
                    <ListItem>
                      <Checkbox label='Connect to WiFi'
                                checked={this.state.config.network.wifi}
                                onChange={this.handleNetworkChange.bind(this, 'wifi')} />
                    </ListItem>
                    <div style={ this.state.config.network.wifi ? { display: 'block'} : { display: 'none'} }>
                      <ListItem>
                        <Dropdown auto label={ t("config_wifi_ssid") }
                                  onChange={this.handleNetworkChange.bind(this, 'ssid')}
                                  source={wifiNets}
                                  value={this.state.config.network.ssid} />
                        <IconButton style={{ padding: 'revert' }} icon='refresh' onClick={this.getWifiNetworks} />
                      </ListItem>
                      <ListItem>
                        <Input type='password' label={ t("config_wifi_pw") } name='password'
                               value={this.state.hasWiFi && this.state.config.network.ssid !== '' ? this.state.config.network.password : ''}
                               onChange={this.handleNetworkChange.bind(this, 'password')} />
                      </ListItem>
                      <ListItem>
                        <div>
                          <Button label={ t("config_check_wifi") } onClick={this.checkWiFi}
                                  disabled={ this.state.config.network.ssid === '' && this.state.config.network.pasword === ''}
                                  primary raised />
                        </div>
                        <TooltipIcon icon="info"
                                     tooltipShowOnClick={true}
                                     tooltip={ t("tooltip_wifi_test") }/>
                      </ListItem>
                      <ListItem>
                        <Checkbox label={ t("config_net_static")}
                                  disabled={!this.state.validWiFi}
                                  checked={this.state.config.enableStaticWifi}
                                  onChange={this.handleChange.bind(this, 'enableStaticWifi')} />
                      </ListItem>
                      <ListItem>
                        <TooltipInput type='text' label={ t("config_net_static_ip") }
                                      tooltip={t("config_net_static_tooltip")}
                                      hint="10.0.0.10"
                                      className={styles.ipAddressInput} theme={styles}
                                      value={this.state.config.staticIPWifi}
                                      disabled={!this.state.config.enableStaticWifi}
                                      onChange={this.handleChange.bind(this, 'staticIPWifi')} />
                      </ListItem>
                      <ListItem>
                        <TooltipInput type='text' label={ t("config_net_dns_server") }
                                      tooltip={t("config_net_dns_tooltip")}
                                      hint="8.8.8.8"
                                      className={styles.ipAddressInput} theme={styles}
                                      value={this.state.config.dnsServerWifi}
                                      //disabled={!this.state.config.enableStaticWifi}
                                      onChange={this.handleChange.bind(this, 'dnsServerWifi')} />
                      </ListItem>
                    </div>
                  </List>
                </Tab>
              </Tabs>
              <div>
                <Button label={ t("config_save_reboot") } onClick={this.submitNetwork} primary raised />
              </div>
            </Tab>

            <Tab label={ t("config_udp") }>
              <UdpStreamsEditor streams={this.state.config.streams} onStreamsUpdated={this.onStreamsUpdated} />
            </Tab>
          </Tabs>
          
          
          <Snackbar
            action='Ok'
            active={this.state.snackActive}
            icon='mood'
            timeout={2000}
            label={this.state.snackMessage}
            onClick={this.handleSnackbarClick}
            onTimeout={this.handleSnackbarClick}
            ref='snackbar'
            type='accept'
          />

          <Dialog
            actions={restartActions}
            active={this.state.restartDialogActive}
            onEscKeyDown={this.handleRestartDialogToggle}
            onOverlayClick={this.handleRestartDialogToggle}
            title={ t("password_confirmation") }>
              <div style={ !this.state.requestSent ? { display: 'none'} : null}>
                <ProgressBar mode='indeterminate'/>
              </div>
              <article style={ this.state.requestSent ? { display: 'none'} : null}>
                <Input type='password' label={ t("pw_field") + " ( " + t("myshake_user") + i18n.t("field_pw_hint").toLowerCase() + ": " + this.state.hint +" )" }
                       value={this.state.password}
                       onChange={this.handleLocalChange.bind(this, 'password')}
                       onKeyPress={(ev) => { if (ev.key == 'Enter') this.saveConfig(false) }} />
              </article>
          </Dialog>

          <Dialog
            active={this.state.defaultRestartActive}>
              <p style={{margin: '5px auto'}}>{ t("default_password_title") }</p>
              <ProgressBar mode='indeterminate'/>
          </Dialog>
          
          <Dialog
            actions={purposeDialogActions}
            active={this.state.purposeDialogActive}
            onEscKeyDown={this.handlePurposeDialogToggle}
            onOverlayClick={this.handlePurposeDialogToggle}
            title={ t("config_ds_purpose") }>
            <article>
              <Input type='text' label={ t("config_ds_purpose") }
                     value={this.state.config.dataSharing.purpose}
                     onChange={this.handleSharingChange.bind(this, 'purpose')}
                     onKeyPress={this.handleKeyPress.bind(this, this.handlePurposeDialogToggle)} />
            </article>
          </Dialog>
          
          <Dialog
            actions={warningActions}
            active={this.state.warningActive}
            onEscKeyDown={this.handleWarningToggle}
            onOverlayClick={this.handleWarningToggle}
            title={ t("config_ds_loc_warn") } >
            <p>{ t("config_ds_loc_warn_note") }</p>
          </Dialog>
          
          <Dialog
            actions={unsavedActions}
            active={this.state.unsavedActive}
            title={ t("config_unsaved_warn") } >
            <br />
            <p>{ t("config_unsaved_note") }</p>
          </Dialog>
          
          <Dialog
            actions={validationActions}
            active={this.state.validationActive}
            onEscKeyDown={this.handleValidationToggle}
            onOverlayClick={this.handleValidationToggle}
            title={ t("config_invalid_warn") } >
            <p>{ t("config_invalid_note") }</p>
            {missingFields}
          </Dialog>

          <Dialog
            actions={licenseActions}
            active={this.state.licenseActive}
            onEscKeyDown={this.handleLicenseToggle}
            onOverlayClick={this.handleLicenseToggle}
            title={ t("config_license_dialog_title") }>
            <p>{ t("config_license_dialog") } <a href="https://raspberryshake.org/products/license/" target="_blank">{ t("config_license_dialog_link") }</a>.</p>
          </Dialog>

          
          <Dialog active={this.state.wifiDialog} >
            <div>
              <p style={{margin: '5px auto'}}>{ t("config_checking_wifi") }</p>
              <ProgressBar mode='indeterminate'/>
            </div>
          </Dialog>
          
          <Snackbar
            active={this.state.wifiSnackActive}
            action="Ok"
            icon="info"
            timeout={2000}
            label={ t("config_wifi_fail") }
            onTimeout={this.handleWifiSnackbarClick}
            ref="snackbar"
            type="accept" />
          
          {/* <Snackbar
            action='Ok'
            active={this.state.dataSnackActive}
            icon='info'
            timeout={2000}
            label={ t("config_missing_warn") }
            onClick={this.handleDataSnackbarClick}
            onTimeout={this.handleDataSnackbarClick}
            ref='snackbar'
            type='accept'
          /> */}

          <Snackbar
            action='Ok'
            active={this.state.validWifiSnackActive}
            icon='info'
            timeout={2000}
            label={ t("config_wifi_success") }
            onClick={this.handleValidWifiSnackbarClick}
            onTimeout={this.handleValidWifiSnackbarClick}
            ref='snackbar'
            type='accept'
          />

          <Snackbar
            active={this.state.wifiFailSnackActive}
            action="Ok"
            icon="error"
            timeout={3000}
            label={ t("config_load_wifi_fail") }
            onTimeout={this.handleWifiFailSnackbarClick}
            ref="snackbar"
            type="accept" />

          <Prompt
            when={this.blockNavigation && this.state.dirtyConfig}
            message={t("config_unsaved_note")}
          />
        </section>
        )
        }
      </I18n>
    );
}
}

export default withRouter(Config);
