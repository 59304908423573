import React from 'react';
import ReactDOM from 'react-dom';

import { Input, Button, IconButton, Dropdown, Switch, Snackbar, Tooltip, Checkbox } from 'react-toolbox';
import { List, ListItem, ListSubHeader, ListDivider, ListCheckbox } from 'react-toolbox/lib/list';

import { I18n } from 'react-i18next';

import i18n from './i18n'

import 'whatwg-fetch';

class WiFiConfig extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadConfig();
  }

  state = {
    snackActive: false,
    ssid: "",
    pw: ""
  }

  loadConfig = () => {
    fetch('/api/config', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, ssid: data.network.ssid, pw: data.network.password});
    });
  }

  handleChange = (name, value) => {
    this.setState({...this.state, [name]: value});
  }

  handleSnackbarClick = (snackbar) => {
    this.setState({
      ...this.state,
      snackActive: false
    });
  }

  submit = () => {
    fetch("/api/wifi", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "ssid": this.state.ssid,
        "pw": this.state.pw
      })
    }).then((response) => {
      this.setState({...this.state, snackActive: true});
    });
  }
    
  render() {
    return (
      <I18n ns="translations">
        {
          (t, { i18n }) => (         
            <section>
              <List>
                <ListSubHeader caption='WiFi settings' />
                <ListItem>
                  <Input type='text' label={ t("config_wifi_ssid") } name='ssid' 
                         value={this.state.ssid} 
                         onChange={this.handleChange.bind(this, 'ssid')} />
                </ListItem>
                <ListItem>
                  <Input type='password' label={ t("config_wifi_pw") } name='password'
                         value={this.state.pw} 
                         onChange={this.handleChange.bind(this, 'pw')} />
                </ListItem>
                <ListDivider />
                <Button label="Save" onClick={this.submit} primary raised />
              </List>
              <Snackbar
                action='Ok'
                active={this.state.snackActive}
                icon='mood'
                timeout={2000}
                label={ t("wifi_confirm") }
                onClick={this.handleSnackbarClick}
                onTimeout={this.handleSnackbarClick}
                ref='snackbar'
                type='accept'
              />
            </section>
          )
        }
      </I18n>
    );
  }
}

export default WiFiConfig;
