import React from 'react';
import ReactDOM from 'react-dom';

import { Input, Button, IconButton, ProgressBar } from 'react-toolbox';
import { List, ListItem, ListSubHeader, ListDivider, ListCheckbox } from 'react-toolbox/lib/list';
import Dialog from 'react-toolbox/lib/dialog';
import { Link } from 'react-router';

import { HashLink } from 'react-router-hash-link';

import { I18n } from 'react-i18next';

import i18n from './i18n'

import { round } from './utils.js';

import 'whatwg-fetch';

import styles from '../style/actions.scss';

class Home extends React.Component {

  state = {
    station: "N/A",
    network: "N/A",
    location: "",
    channel: "N/A",
    shakeModel: '',
    model: '',
    systemStatus: '',
    sysVersion: '',
    odfVersion: '',
    ip: '',
    eth_ip: '',
    wifi_ip: '',
    mac: '',  
    consumer: '',
    producer: '',
    dataSharing: '',
    serverConnected: false,
    lat: '0',
    long: '0',
    elevation: '0',
    uptime: '0s',
    time: 'N/A',
    diskspace: 'N/A',
    cputemp: 'N/A',
    rebootDialog: false,
    standalone: false
  }

  constructor(props) {
    super(props);

    this.state.standalone = this.props.standalone
  }

  getSummary = () => {
    fetch('/api/summary', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {      
      this.setState(data);
    }).catch((error) => {
      console.log("Error fetching summary data.")
    })

    this.loadStandalone()
  }

  loadStandalone = () => {
    fetch('/api/standalone', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then((data) => {
      this.setState({...this.state, standalone: data.standalone})
    })
  }

  componentWillMount() {
    this.getSummary();
    this.interval = setInterval(this.getSummary, 3000);
  }

  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  
  floatToDMS(value) {
    var floatVal = parseFloat(value)
    var negative = floatVal < 0
    floatVal = Math.abs(floatVal)
    var degrees = Math.floor(floatVal)
    var minutes = Math.floor((floatVal - degrees) * 60)
    var seconds = round((floatVal - degrees - (minutes / 60)) * 3600, 3)

    var sign = negative ? '-' : ''

    return `${sign}${degrees}° ${minutes}' ${seconds}''`
  }

  formatAvailableSpace(available) {
    if  (available > 1000) {
      return `${(available/1024).toFixed(1)} Gb`
    } else {
      return `${available} Mb`
    }
  }

  render() {
    var overviewStyle = {
      fontWeight: 300
    };

    var lineStyle = {
      lineHeight: 1.5
    };

    var labelStyle = {
      textAlign: "right"
    };

    var badLabelStyle = {
      color: "#8B0000",
    };

    var goodLabelStyle = {
      color: '#006400',
    };

    var boldLabelStyle = {
      textAlign: "right",
      fontWeight: 700
    }

    var lineEndStyle = {
      lineHeight: 1.5,
      clear: "both",
      width: "100%",
      paddingBottom: "5px",
      borderBottom: "1px solid #303f9f",
      marginBottom: "5px" 
    };

    var noneStyle = {
      display: "none"
    };

    var tableStyle = {
      borderCollapse: "collapse"
    };

    var buttonsStyle = {
      marginTop: "2em"
    }

    var odfVersion = (
      <tr style={lineStyle}>
        <td style={labelStyle}><label >ODF Version</label></td>
        <td>:</td>
        <td>
          <span>
            <a >{this.state.odfVersion}</a>
          </span>
        </td>
      </tr>);

    return (
      <I18n ns="translations">
        {
          (t, { i18n }) => ( 
            <section style={overviewStyle}>

              <table style={tableStyle}>
                <tbody>
                <tr style={lineStyle}>
                    <td style={labelStyle}><label >Shake Model</label></td>
                    <td>:</td>
                    <td><span>{this.state.shakeModel}</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_rpi") }</label></td>
                    <td>:</td>
                    <td><span>{this.state.model}</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_status") }</label></td>
                    <td>:</td>
                    <td><span>{ t(this.state.systemStatus) }</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_version") }</label></td>
                    <td>:</td>
                    <td>
                      <span>
                        <a href="http://manual.raspberryshake.org/changelog.html#changelog" target="_blank">
                          {this.state.sysVersion}
                        </a>
                      </span>
                    </td>
                  </tr>
                  { this.state.eth_ip ?
                    <tr style={lineStyle}>
                      <td style={this.state.wifi_ip ? boldLabelStyle : labelStyle}><label >Ethernet { t("home_ip") }</label></td>
                      <td>:</td>
                      <td><span>{this.state.eth_ip ? this.state.eth_ip : "Loading..." }</span></td>
                    </tr> : null
                  }
                  { this.state.eth_ip ?
                    <tr style={this.state.wifi_ip ? lineStyle : lineEndStyle }>
                      <td style={this.state.wifi_ip ? boldLabelStyle : labelStyle}><label >Ethernet { t("home_mac") }</label></td>
                      <td>:</td>
                      <td><span>{this.state.mac ? this.state.mac : "Loading..." }</span></td>
                    </tr> : null
                  }
                  { this.state.wifi_ip ?
                    <tr style={lineStyle}>
                      <td style={labelStyle}><label >WiFi { t("home_ip") }</label></td>
                      <td>:</td>
                      <td><span>{this.state.wifi_ip}</span></td>
                    </tr> : null
                  }
                  { this.state.wifi_ip ?
                    <tr style={lineEndStyle}>
                      <td style={labelStyle}><label >WiFi { t("home_mac") }</label></td>
                      <td>:</td>
                      <td><span>{this.state.wifi_mac}</span></td>
                    </tr> : null 
                  }
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_producer") }</label></td>
                    <td>:</td>
                    <td style={this.state.producer === "ON" ? goodLabelStyle : badLabelStyle }><span>{ t(this.state.producer) }</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_consumer") }</label></td>
                    <td>:</td>
                    <td style={this.state.consumer === "ON" ? goodLabelStyle : badLabelStyle }><span>{ t(this.state.consumer) }</span></td>
                  </tr>
                  <tr style={lineStyle}>
                      <td style={labelStyle}><label >Off-line Mode</label></td>
                      <td>:</td>
                      <td style={this.state.standalone ? goodLabelStyle : badLabelStyle }><span>{ this.state.standalone ? t("ON") : t("OFF") }</span></td>
                  </tr>
                  <tr style={lineStyle}>
                      <td style={labelStyle}><label >{ t("home_forwarding") }</label></td>
                      <td>:</td>
                      <td style={this.state.dataSharing === "ON" ? goodLabelStyle : badLabelStyle }><span>{ t(this.state.dataSharing) }</span></td>
                  </tr>
                  <tr style={this.state.dataSharing === 'ON' ? lineStyle : lineEndStyle}>
                    <td style={labelStyle}><label >{ t("home_server") }</label></td>
                    <td>:</td>
                    <td style={this.state.serverConnected ? goodLabelStyle : badLabelStyle }><span>{this.state.serverConnected ? t("home_server_con") : t("home_server_discon") }</span></td>
                  </tr>
                  <tr style={this.state.dataSharing === 'ON' ? lineStyle : noneStyle}>
                      <td style={labelStyle}><label ><HashLink to="/config#map" >{ t("home_lat") }</HashLink></label></td>
                      <td>:</td>
                      <td><span><HashLink to="/config#map" >{this.state.lat}</HashLink></span></td>
                  </tr>
                  <tr style={this.state.dataSharing === 'ON' ? lineStyle : noneStyle}>
                      <td style={labelStyle}><label ><HashLink to="/config#map" >{ t("home_lon") }</HashLink></label></td>
                      <td>:</td>
                      <td><span><HashLink to="/config#map" >{this.state.long}</HashLink></span></td>
                  </tr>
                  <tr style={this.state.dataSharing === 'ON' ? lineEndStyle : noneStyle}>
                    <td style={labelStyle}><label >{ t("home_elev") }</label></td>
                    <td>:</td>
                    <td><span>{this.state.elevation} m</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_systime") }</label></td>
                    <td>:</td>
                    <td><span>{this.state.time}</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle} ><label>{ t("home_diskusage") }</label></td>
                    <td>:</td>
                    <td>
                      { typeof this.state.diskspace !== 'string' ?
                        <span>
                          {this.state.diskspace.used}% {t("USED")} -- { this.formatAvailableSpace(this.state.diskspace.available)} {t("AVAILABLE")}
                        </span>
                        :
                        <span>
                          {this.state.diskspace}
                        </span>
                      }
                    </td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle} ><label>{ t("home_cpu_temp") }</label></td>
                    <td>:</td>
                    <td><span>{this.state.cputemp}</span></td>
                  </tr>
                  <tr style={lineStyle}>
                    <td style={labelStyle}><label >{ t("home_uptime") }</label></td>
                    <td>:</td>
                    <td>
                      { typeof this.state.uptime !== 'string' ?
                        <span>
                          {this.state.uptime.days} {t("DAYS")} {this.state.uptime.hours} {t("HOURS")} {this.state.uptime.minutes} {t("MINUTES")} { this.state.uptime.seconds > 0 ? this.state.uptime.seconds + " "+ t("SECONDS") : ""}
                        </span>
                        :
                        <span>
                          {this.state.uptime}
                        </span>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <article style={buttonsStyle}>
                <Button target="_blank" href="https://shakenet.raspberryshake.org/"
                  label={t("go_to") + ' ShakeNet' } 
                  className={styles.linkButton} theme={styles} raised  />
              </article>
              <article>
                <Button target="_blank" href="https://stationview.raspberryshake.org/"
                  label={t("go_to") + ' ' + t("links_d_station") } 
                  className={styles.linkButton} theme={styles} raised  />
              </article>
              <article>
                <Button label={ t("home_dl_logs") } target="_blank" href="/api/logs"
                        className={styles.downloadButton} theme={styles} raised primary />
              </article>
            </section>
          )
        }
      </I18n>
    );
  }
}

export default Home;
