import React from 'react';
import ReactDOM from 'react-dom';
//import { Router, Route, IndexRoute, Link, browserHistory } from 'react-router';
import { withRouter } from 'react-router'
import { BrowserRouter, Route, Link, NavLink } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { AppBar, Checkbox, Dialog, IconButton, MenuItem, Link as RLink, ProgressBar, Tooltip } from 'react-toolbox';
import Navigation from 'react-toolbox/lib/navigation';
import { Layout, NavDrawer, Panel, Sidebar, Dropdown } from 'react-toolbox';
import { List, ListItem, ListSubHeader, ListDivider, ListCheckbox } from 'react-toolbox/lib/list';
import FontIcon from 'react-toolbox/lib/font_icon';

import { I18n } from 'react-i18next';

import i18n from './i18n'

import 'whatwg-fetch';

import Config from './Config.js';
import Actions from './Actions.js';
import Home from './Home.js';
import WiFiConfig from './WiFiConfig.js';

import theme from '../style/theme.scss';
import appBarTheme from '../style/appBar.scss';
import footerTheme from '../style/footer.scss';

const TooltippedDiv = Tooltip(props => <div {...props} />);

@withRouter
class App extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.props.history.listen((ev) => {
      console.log('history listen: ', ev)
      var routes = {
        "/" : false,
        "/config" : false,
        "/actions" : false
      };
      routes[ev.pathname] = true;
      console.log('ev: ', ev)
      var rebooting = false
      if (ev.pathname === "/" && ev.state && 'rebooting' in ev.state && ev.state.rebooting) {
        rebooting = true
      }
      this.setState({...this.state, routesActive: routes, rebootDialog: rebooting});
    });

    window.testingWiFi = false;
    
    fetch('/api/apmode', {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, loaded: true, apMode: data.apMode});
    });

    fetch('/api/wifi', {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, checkedWifi: true, hasWiFi: data.wifi});
    });

    fetch('/api/devicetype', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then((data) => {
      console.log('device type: ', data)
      this.setState({...this.state, deviceType: data.deviceType})
    })

    this.countries = [
      { label: 'English', value: 'en'},
      { label: 'Español', value: 'es'},
      { label: 'Français', value: 'fr'}
    ];

    i18n.on('loaded', (loaded) => {
      this.setState({...this.state, lang: i18n.language})
    })
  }

  state = {
    lang: 'en',
    loaded: false,
    apMode: false,
    deviceType: "",
    checkedWiFi: false,
    hasWiFi: false,
    standalone: false,
    checkedModel: false,  
    validModel: false,
    station: "",
    network: "",
    location: "",
    channel: "",
    drawerActive: false,
    drawerPinned: false,
    sidebarPinned: false,
    wasDown: false,
    inactiveDialog: false,
    rebootDialog: false,
    rpiStatus: 0, // 0 for normal, 1 restarted, 2 shutdown
    routesActive: {
      "/" : false,
      "/config" : false,
      "/actions" : false
    },
    tooltips: {
      helicorder: "links_d_heli",
      swarm: "swarm_download",
      stationview: "rs_st_view",
      forums: "rs_on_forum",
      home: "rs_hp",
      shop: "rs_on_store"
    },
    initialConfig: {
      network: {
        static: false,
        wifi: false
      },
      dataSharing: {},
      other: { heliscaling: 1.0, archkeep: 7 },
      ntpServers: []
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }
    
  componentWillMount() {
    this.loadData();
    //this.loadTooltips();
  }

  componentDidMount() {
    this.interval = setInterval(this.runCheck, 2000);
  }

  loadData = () => {
    fetch('/api/rp', {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, checkedModel: true, validModel: data.validModel});
    });

    fetch('/api/scnl', {
      method: 'GET'
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, station: data.station, network: data.network, location: data.location, channel: data.channel});
    });

    fetch('/api/config', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      var config = Object.assign(this.state.initialConfig, data);
      this.setState({...this.state, initialConfig: config});
    });

    this.loadStandalone()
  }

  loadStandalone = () => {
    fetch('/api/standalone', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then((data) => {
      this.setState({...this.state, standalone: data.standalone})
    })
  }

  loadTooltips = () => {
    fetch('/api/tooltips', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, tooltips: data});
    });
  }


  runCheck = () => {
    if (!window.testingWiFi) {
      console.log('getting api/test')
      fetch('/api/test', {
        method: 'GET'
      }).then((response) => {
        return response.json();
      }).then((data) => {
        console.log('data running, route active: ' + this.state.routesActive["/"])
        if(data.running && this.state.wasDown) {
          if(!this.state.routesActive["/"]) {
            this.props.history.replace("/");
          }
          this.loadData();
          this.setState({...this.state, inactiveDialog: false, rebootDialog: false, wasDown: false});
        }
      }).catch((error) => {
        console.log('error: ', error)
        console.log('route active: ' + this.state.routesActive["/"])
        if(!this.state.routesActive["/"]) {
          this.props.history.replace("/");
        }
        this.setState({...this.state, inactiveDialog: true, rebootDialog: false, wasDown: true});
      });
    }
  }

  toggleDrawerActive = () => {
    this.setState({...this.state, drawerActive: !this.state.drawerActive });
  }
  
  toggleDrawerPinned = () => {
    this.setState({...this.state, drawerPinned: !this.state.drawerPinned });
  }
  
  toggleSidebar = () => {
    this.setState({...this.state, sidebarPinned: !this.state.sidebarPinned });
  }

  setRPiState = (state) => {
    this.setState({...this.state, rpiStatus: state});
  }

  updateConfig = () => {
    fetch('/api/config', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      this.setState({...this.state, initialConfig: data});
    });
  }

  navigate = (dest) => {
    this.toggleDrawerActive();
    this.props.history.push(dest);
  }

  renderContent = () => {
    if (!this.state.inactiveDialog && this.state.checkedModel) {
      if (this.state.validModel) {
        var props = {
          hasWiFi: this.state.hasWiFi,
          deviceType: this.state.deviceType,
          network: this.state.network,
          station: this.state.station,
          setRPiState: this.setRPiState.bind(this),
          initialConfig: this.state.initialConfig,
          updateConfig: this.updateConfig.bind(this),
          standalone: this.state.standalone
        }
        return (
          <div>
            <Route exact path="/" render={(routeProps) => React.createElement(Home, {...routeProps, ...props})} />
            <Route exact path="/config" render={(routeProps) => React.createElement(Config, {...routeProps, ...props})} />
            <Route exact path="/actions" render={(routeProps) => React.createElement(Actions, {...routeProps, ...props})} />
          </div>
        )
      } else {
        return ( <h1> { i18n.t("invalid_model") }</h1> );
      }
    }
  }

  setLanguage = (event) => {
    console.log(event)
    i18n.changeLanguage(event.target.value)
    this.setState({lang: event.target.value})
    this.forceUpdate()
  }

  renderLanguageOptions() {
    return this.countries.forEach(c => <option value={c.value}>{c.label}</option>)
  }
  
  render() {
    var linkStyle = {

    };

    const content = this.state.validModel && !this.state.inactiveDialog ? 
                    this.props.children && React.cloneElement(this.props.children, {
                      hasWiFi: this.state.hasWiFi
                    }) 
                  : ( <h1>{ i18n.t("invalid_model") }</h1> );
    const title = [this.state.network, this.state.station, this.state.location, this.state.channel].join('.');
      
    const normalMode = (
        <div style={{height: "100%" }}>
          <I18n ns="translations">
            {
              (t, { i18n }) => (  
                <Layout>
                  <Panel>
                    <AppBar className={appBarTheme.titlebar} theme={appBarTheme} >
                      <img src="logo-icon.svg" />
                      <div style={{ marginLeft: "1em" }}>
                        <NavLink exact activeStyle={{ display: 'none'}} to="/" >
                          <FontIcon value="home" />
                          <div>
                            <div style={{ marginLeft: "-10px" }}>
                              <span ref="homeLabel">{ t("home") }</span>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink activeStyle={{ display: 'none'}} to="/config" ><FontIcon value="settings" />
                          <div>
                            <div style={{ marginLeft: "-18px" }}>
                              <span ref="settingsLabel">{ t("settings") }</span>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink activeStyle={{ display: 'none'}} to="/actions" ><FontIcon value="gavel" />
                          <div >
                            <div style={{ marginLeft: "-16px" }}>
                              <span ref="actionsLabel">{ t("actions") }</span>
                            </div>
                          </div>
                        </NavLink>
                      </div>
                      <h1>{title}</h1>
                    </AppBar>
                    <Dialog active={this.state.inactiveDialog}
                            title={ t("rpi_down") } >
                      { this.state.rpiStatus == 1 ?
                        <div>
                          <p style={{margin: '5px auto'}}>{ t("rpi_down_restart") }</p>
                          <ProgressBar mode='indeterminate'/>
                        </div>
                        :
                        <div>
                          <p style={{margin: '5px auto'}}>{ t("rpi_down_shutdown") }</p>
                        </div>
                      }
                    </Dialog>
                    <Dialog active={this.state.rebootDialog} >
                      <div>
                        <p style={{margin: '5px auto'}}>{ t("config_wifi_reboot") }</p>
                        <ProgressBar mode='indeterminate'/>
                      </div>
                    </Dialog>
                    <div style={{ flex: 1, overflowY: 'auto', padding: '1.8rem' }}>
                      {this.renderContent()}
                    </div>
                    <footer className={footerTheme.footer}  >
                      <Navigation type="horizontal">
                        <TooltippedDiv tooltip={t(this.state.tooltips.helicorder)} >
                          <a target="_blank" href="/heli">
                            <img src="/helicorder.png" width="48" />
                          </a>
                        </TooltippedDiv>
                        <TooltippedDiv tooltip={t(this.state.tooltips.swarm)} >
                          <a target="_blank" href="/download/SWARM.zip">
                            <img src="/swarm-download.png" width="48" />
                          </a>
                        </TooltippedDiv>
                        <TooltippedDiv tooltip={t(this.state.tooltips.stationview)} >
                          <a target="_blank" href="https://stationview.raspberryshake.org/">
                            <img src="/station-view.png" width="48" />
                          </a>
                        </TooltippedDiv>
                        <TooltippedDiv tooltip={t(this.state.tooltips.forums)} >
                          <a target="_blank" href="https://raspberryshake.org/help/">
                            <img src="/forum.png" width="48" />
                          </a>
                        </TooltippedDiv>
                        <TooltippedDiv tooltip={t(this.state.tooltips.home)} >
                          <a target="_blank" href="http://raspberryshake.org/">
                            <img src="/home.png" width="48" />
                          </a>
                        </TooltippedDiv>
                        <TooltippedDiv tooltip={t(this.state.tooltips.shop)} >
                          <a target="_blank" href="https://shop.raspberryshake.org/">
                            <img src="/shop.png" width="48" />
                          </a>
                        </TooltippedDiv>
                      </Navigation>
                      <Navigation type="horizontal">
                        <select onChange={this.setLanguage} value={this.state.lang}
                                style={{ margin: '0 0.5rem' }}>
                          <option value="en">English</option>
                          <option value="es">Español</option>
                          <option value="fr">Français</option>
                          <option value="it">Italiano</option>
                          <option value="nl">Nederlands</option>
                        </select>
                        <span style={{ float: 'right' }}>
                          Copyright 2021 - Raspberry Shake S.A.
                        </span>
                      </Navigation>
                    </footer>
                  </Panel>
                </Layout>
              )
            }
          </I18n>
        </div>
    );

    const apMode = (
      <Layout>
        <Panel>
          <WiFiConfig />
          <footer style={{ color: "#FFFFFF", background: "#303f9f", position: "relative", bottom: 0, width: "100%" }}>
            <Navigation type="horizontal">
              <RLink active href="http://www.osop.com.pa" label={ i18n.t("poweredby") } target="_blank" />
            </Navigation>
          </footer>
        </Panel>
      </Layout>
    );

    if(this.state.loaded) {
      if(this.state.apMode)
        return apMode;
      else
        return normalMode;
    } else {
      return null;
    }
  }
}


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("app"));
