import React from 'react';
import ReactDOM from 'react-dom';

import { Input, Dialog, Button, Tab, Tabs, Tooltip, Snackbar, ProgressBar } from 'react-toolbox';

import Loader from 'react-loading-animation';

import { I18n } from 'react-i18next';

import i18n from './i18n'

import 'whatwg-fetch';

import styles from '../style/actions.scss';
import swarmtheme from '../style/swarm_button.scss';

const TooltipButton = Tooltip(Button);
const TooltipInput = Tooltip(Input);
const TooltippedDiv = Tooltip(props => <div {...props} />);

class Actions extends React.Component {

  constructor(props) {
    super(props);

    this.state.standalone = this.props.standalone
  }

  state = {
    index: 1,
    linksIndex: 0,
    dialogActive: false,
    passwdDialogActive: false,
    confirmDialogActive: false,
    saDialogActive: false,
    actionToConfirm: '',
    defaultRestartActive: false,
    command: '',
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
    hint: '',
    editHint: '',
    confirmError: '',
    snackActive: false,
    snackMessage: '',
    requestSent: false,
    serverConnected: false,
    standalone: false,
    tracesDialog: false,
  };

  componentDidMount() {
    this.loadHint()
    this.loadSummary()
  }

  loadHint = () => {
    fetch("/api/hint", {
      method: 'GET'
    })
      .then((response) => { return response.json();})
      .then((data) => {
        this.setState({...this.state, hint: data.hint, editHint: data.hint});
      });
  }

  loadSummary = () => {
    fetch('/api/summary', {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {      
      this.setState({...this.state, serverConnected: data.serverConnected});
    });

    this.loadStandalone()
  }

  loadStandalone = () => {
    fetch('/api/standalone', {
      method: 'GET'
    }).then(function(response) {
      return response.json()
    }).then((data) => {
      this.setState({...this.state, standalone: data.standalone})
    })
  }

  testDefaultPassword = () => {
    return fetch("/api/pw-test", {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": "shakeme"
      })
    })
    .then(function(response) {
      return response.json()
    })
    .then((data) => {
      return data.success
    });
  }

  callReboot = (tryDefault = false) => {
    this.setState({...this.state, requestSent: true});
    return fetch("/api/reboot", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": tryDefault ? "shakeme" : this.state.password
      })
    })
    .catch(function(error) {
      console.log("error: ", error);
      this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("unsp_error")});
    })
    .then((response) => { return response.json(); })
    .then((data) => {
      if(data.success) {
        this.props.setRPiState(1);
        this.setState({...this.state, dialogActive: false, password: '', requestSent: false, defaultRestartActive: false,
                        snackActive: true, snackMessage: i18n.t("restarting") },
                      () => this.props.history.replace({ pathname: "/", state: { rebooting: true }}));
      } else {
        if (!tryDefault) {
          this.setState({...this.state, requestSent: false, snackActive: true, defaultRestartActive: false,
                        snackMessage: i18n.t("auth_fail")});
        } else {
          throw 'Default auth failed'
        }
      }
    })
  };

  callShutdown = (tryDefault = false) => {
    this.setState({...this.state, requestSent: true});
    fetch("/api/shutdown", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": tryDefault ? "shakeme" : this.state.password
      })
    })
    .catch(function(error) {
      console.log("error: ", error);
      this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("unsp_error")});
    })
    .then((response) => { return response.json(); })
      .then((data) => {
        console.log('success: ', data.success);
        if(data.success) {
          this.props.setRPiState(2);
          this.setState({...this.state, dialogActive: false, requestSent: false, defaultRestartActive: false,
                         snackActive: true, snackMessage: i18n.t("shutdown")});
        } else {
          this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("auth_fail")});
        }
      }).catch(function(error) {
        if (!tryDefault) {
          this.setState({...this.state, requestSent: false, snackActive: true, defaultRestartActive: false,
                        snackMessage: i18n.t("auth_fail")});
        } else {
          throw 'Default auth failed'
        }
      });
  };

  callPasswd = () => {
    this.setState({...this.state, requestSent: true})
    fetch("/api/passwd", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "pw": this.state.password,
        "new_pw": this.state.newPassword,
        "hint": this.state.editHint
      })
    }).then((response) => { return response.json(); })
      .then((data) => {
        console.log('success: ', data.success);
        if(data.success) {
          this.setState({...this.state, password: '', newPassword: '', newPasswordConfirm: '', hint: this.state.editHint,
                         passwdDialogActive: false, requestSent: false,
                         snackActive: true, snackMessage: i18n.t("password")});
        } else {
          this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("auth_fail")});
        }
      }).catch(function(error) {
        console.log("error: ", error);
        this.setState({...this.state, requestSent: false, snackActive: true, snackMessage: i18n.t("unsp_error")});
      });
  };

  handleLocalChange = (name, value) => {
    this.setState({...this.state, [name]: value});
  };

  handlePasswordChange = (name, value) => {
    this.setState({...this.state, [name]: value, confirmError: ''});
  };

  handleTabChange = (index) => {
    this.setState({...this.state, index: index});
  };

  handleLinksTabChange = (index) => {
    this.setState({...this.state, linksIndex: index});
  };

  handleDialogToggle = (command) => {
    this.setState({...this.state, dialogActive: !this.state.dialogActive, command: command});
  };

  handleSnackbarClick = () => {
    this.setState({...this.state, snackActive: !this.state.snackActive});
  };

  handleDialogConfirm = () => {
    const commandFn = this.state.command
    commandFn()
  };

  handleConfirmDialogToggle = () => {
    this.setState({...this.state, confirmDialogActive: !this.state.confirmDialogActive});
  }

  handleActionConfirm = () => {
    const commandFn = this.state.command
    commandFn(true)
    this.handleConfirmDialogToggle()
  }

  handleActionCancel = () => {
    this.handleConfirmDialogToggle()
  }

  confirmOrPassword = (commandFn) => {
    this.testDefaultPassword()
      .then(success => {
        if (success) {
          this.setState({...this.state, command: commandFn})
          this.handleConfirmDialogToggle()
        } else {
          this.setState({...this.state, dialogActive: !this.state.dialogActive, command: commandFn, requestSent: false});
        }
      })
  }

  doShutdownOrReboot = (command) => {
    if (command == "shutdown") {
      this.confirmOrPassword(this.callShutdown)
    } else if (command == "reboot") {
      this.confirmOrPassword(this.callReboot)
    }
  };

  handlePasswdDialogToggle = () => {
    this.setState({...this.state, passwdDialogActive: !this.state.passwdDialogActive});
  };

  handlePasswdDialogConfirm = () => {
    if (this.state.newPassword != this.state.newPasswordConfirm) {
      console.log("passwords not the same");
      this.setState({...this.state, confirmError: i18n.t("pwd_not_same")});
    } else {
      this.callPasswd();
    }
  };
  
  handleKeyPress = (callback, ev) => {
    if (ev.key === 'Enter') {
      callback();
    }
  }

  handleSACancel = () => {
    this.setState({...this.state, saDialogActive: false});
  }

  handleSAConfirm = () => {
    this.setState({...this.state, dialogActive: !this.state.dialogActive, command: this.executeToggleStandalone, requestSent: false});
  }

  toggleStandalone = () => {
    if (this.state.standalone) {
      this.testDefaultPassword()
        .then(success => {
          if (success) {
            this.executeToggleStandalone(true)
          } else {
            this.setState({...this.state, dialogActive: !this.state.dialogActive, command: this.executeToggleStandalone, requestSent: false});
          }
        })
    } else {
      this.setState({...this.state, saDialogActive: true});
    }
  }

  executeToggleStandalone = (tryDefault = false) => {
    fetch("/api/standalone", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "standalone": !this.state.standalone
      })
    }).then(_ => {
      this.callReboot(tryDefault)
        .catch(err => {
          console.log('error while doing default password: ', err)
          this.setState({...this.state, dialogActive: !this.state.dialogActive, command: "reboot", requestSent: false});
        })
    })
  }

  getHeliUrl = () => {
    var heliUrl = window.location.origin+"/heli";
    return heliUrl;
  }

  render() {
    const actions = [
      { label: i18n.t("ok"), onClick: this.handleDialogConfirm },
      { label: i18n.t("cancel"), onClick: this.handleDialogToggle.bind(this, "") }
    ]

    const passwdActions = [
      { label: i18n.t("ok"), onClick: this.handlePasswdDialogConfirm },
      { label: i18n.t("cancel"), onClick: this.handlePasswdDialogToggle.bind(this, "") },
    ]

    const confirmActions = [
      { label: i18n.t("ok"), onClick: this.handleActionConfirm },
      { label: i18n.t("cancel"), onClick: this.handleActionCancel },
    ]

    const saActions = [
      { label: i18n.t("ok"), onClick: this.handleSAConfirm },
      { label: i18n.t("cancel"), onClick: this.handleSACancel },
    ]
    
    const shakeUrl = "https://stationview.raspberryshake.org/#?net="+this.props.network+"&sta="+this.props.station;

    // leave out for moment
    const waveform = (
      <article>
        <Button label={ i18n.t("waveform_files") } target="_blank" href="/api/traces"
                className={styles.downloadButton} theme={styles} raised primary />
      </article>);
    
    return (
      <I18n ns="translations">
        {
          (t, { i18n }) => (         
            <section>
              <Tabs index={this.state.index} onChange={this.handleTabChange}
                    className={styles.cleanTabs} theme={styles}>
                <Tab label={ t("downloads") } >
                  <article>
                    <Button label={ t("dl_swarm") } target="_blank" href="/download/SWARM.zip"
                            className={styles.downloadButton} theme={styles} raised primary />
                  </article>
                  <article>
                    <Button label={ t("dl_logs") } target="_blank" href="/api/logs"
                            className={styles.downloadButton} theme={styles} raised primary />
                  </article>
                  <article>
                    <Button label="DATA" target="_blank" href={'ftp://' + window.location.hostname}
                            className={styles.downloadButton} theme={styles} raised primary />
                  </article>
                </Tab>
                <Tab label={ t("links") } >
                  <Tabs index={this.state.linksIndex} onChange={this.handleLinksTabChange}
                        className={styles.cleanSubTabs} theme={styles} >
                    <Tab label={ t("links_data") } >
                      <article>
                        <Button target="_blank" href={this.getHeliUrl()} label={ t("links_d_heli") } 
                                className={styles.dataButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://stationview.raspberryshake.org/"
                                label={ t("links_d_station") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://dataview.raspberryshake.org/"
                                label={ t("links_d_data") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      { this.state.serverConnected ?
                        <article>
                          <Button target="_blank" href={shakeUrl} label={ t("links_d_myshake") } 
                                  className={styles.linkButton} theme={styles} raised  />
                        </article>
                        : null
                      }
                    </Tab>
                    <Tab label={ t("links_support") }>
                      <article>
                        <Button target="_blank" href="https://manual.raspberryshake.org/" label={ t("links_s_manual") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://community.raspberryshake.org/" label={ t("links_s_groups") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://raspberryshake.org/help" label="HELP PAGE" 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                    </Tab>
                    <Tab label={ t("links_media") }>
                      <article>
                        <Button target="_blank" href="https://www.instagram.com/raspishake/" label={ t("links_m_insta") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://twitter.com/raspishake" label={ t("links_m_twitter") }
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://www.youtube.com/channel/UCqxETqiBOCMH7fy-d0XgIWw" label={ t("links_m_youtube") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                      <article>
                        <Button target="_blank" href="https://www.facebook.com/raspishake/" label={ t("links_m_fb") } 
                                className={styles.linkButton} theme={styles} raised  />
                      </article>
                    </Tab>
                  </Tabs>
                  
                  
                </Tab>
                <Tab label={ t("actions") } >
                  <article>
                    <Button label={ this.state.standalone ? t("actions_standalone_off") : t("actions_standalone_on") } onClick={this.toggleStandalone} 
                            className={styles.actionButton} theme={styles} raised />
                  </article>
                  <article>
                    <Button label={ t("actions_restart") } onClick={this.doShutdownOrReboot.bind(this, 'reboot')} 
                            className={styles.actionButton} theme={styles} raised />
                  </article>
                  <article>
                    <TooltipButton label={ t("actions_shutdown") } onClick={this.doShutdownOrReboot.bind(this, 'shutdown')} 
                                   className={styles.actionButton} theme={styles} raised
                                   tooltip={ t("tooltip_shutdown") } />
                  </article>
                  <article>
                    <Button label={ t("actions_pwd") } onClick={this.handlePasswdDialogToggle} 
                            className={styles.actionButton} theme={styles} raised />
                  </article>
                </Tab>
              </Tabs>

              <Dialog
                active={this.state.defaultRestartActive}
                title={ t("default_password_title")} >
                <p style={{margin: '5px auto'}}>{ t("default_password_title")}</p>
                <ProgressBar mode='indeterminate'/>
              </Dialog>

              <Dialog
                active={this.state.tracesDialog}
                title="Generating traces archive..." >
                <p style={{margin: '5px auto'}}>Generating traces archive...</p>
                <ProgressBar mode='indeterminate'/>
              </Dialog>
              
              <Dialog
                actions={actions}
                active={this.state.dialogActive}
                onEscKeyDown={this.handleDialogToggle}
                onOverlayClick={this.handleDialogToggle}
                title={ t("pw_field") }>
                <div style={ !this.state.requestSent ? { display: 'none'} : null}>
                  <ProgressBar mode='indeterminate'/>
                </div>
                <article style={ this.state.requestSent ? { display: 'none'} : null}>
                    <TooltipInput type='password'
                                  label={ i18n.t("pw_field") + "( " + i18n.t("myshake_user") +
                                         i18n.t("field_pw_hint").toLowerCase() + ": " + this.state.hint +" )" }
                                  value={this.state.password} tooltip={this.state.hint}
                                  onChange={this.handleLocalChange.bind(this, 'password')}
                                  onKeyPress={this.handleKeyPress.bind(this, this.handleDialogConfirm)} />
                </article>
              </Dialog>

              <Dialog
                actions={confirmActions}
                active={this.state.confirmDialogActive}
                onEscKeyDown={this.handleActionCancel}
                onOverlayClick={this.handleActionCancel}
                title={'Confirmation'}>
                <p style={{ margin: '5px auto' }}>Are you sure you want to perform the following action: { this.state.actionToConfirm }</p>
              </Dialog>

              <Dialog
                actions={saActions}
                active={this.state.saDialogActive}
                onEscKeyDown={this.handleSACancel}
                onOverlayClick={this.handleSACancel}
                title={'Off-line mode warning'}>
                <p style={{ margin: '5px auto' }}>CAUTION! This is for OFFLINE applications ONLY. Data will not be forwarded to the Server and a Server Connection will not be established. Do not enable unless you plan to use the Raspberry Shake without an Internet connection. See <a href="https://manual.raspberryshake.org/no-network.html">here</a> for more details. </p>
              </Dialog>
              
              <Dialog
                actions={passwdActions}
                active={this.state.passwdDialogActive}
                onEscKeyDown={this.handlePasswdDialogToggle}
                onOverlayClick={this.handlePasswdDialogToggle}
                title={ t("actions_pwd") }
                className={styles.paddlessDialog} theme={styles} >
                <div style={ !this.state.requestSent ? { display: 'none'} : null}>
                  <ProgressBar mode='indeterminate'/>
                </div>
                <article style={ this.state.requestSent ? { display: 'none'} : null}>                  
                  <Input type='password' label={ t("field_pw_old") + " ( hint: " + this.state.hint +" )" }
                         className={styles.dialogTooltip} theme={styles}
                         value={this.state.password}
                         onChange={this.handleLocalChange.bind(this, 'password')} />
                  <Input type='password' label={ t("field_pw_new") }
                         value={this.state.newPassword}
                         onChange={this.handlePasswordChange.bind(this, 'newPassword')} />
                  <Input type='password' label={ t("field_pw_confirm") }
                         value={this.state.newPasswordConfirm}
                         onChange={this.handlePasswordChange.bind(this, 'newPasswordConfirm')}
                         onKeyPress={this.handleKeyPress.bind(this, this.handlePasswdDialogConfirm)}
                         error={this.state.confirmError}/>
                  <Input type='text' label={ t("field_pw_hint") }
                         value={this.state.editHint}
                         onChange={this.handleLocalChange.bind(this, 'editHint')} />
                </article>
              </Dialog>
              
              <Snackbar
                action='Ok'
                active={this.state.snackActive}
                icon='mood'
                timeout={2000}
                label={this.state.snackMessage}
                onClick={this.handleSnackbarClick}
                onTimeout={this.handleSnackbarClick}
                ref='snackbar'
                type='accept'
              />
            </section>
          )
        }
      </I18n>
    );
  }
}

export default Actions;
