import React from 'react';
import ReactDOM from 'react-dom';

import { Input, Button, IconButton } from 'react-toolbox';
import { List, ListItem, ListSubHeader, ListDivider } from 'react-toolbox/lib/list';

import { I18n } from 'react-i18next';
import i18n from './i18n'

import 'whatwg-fetch';

import styles from '../style/config.scss';
import theme from '../style/udp_streams.scss';

class UdpStreamsEditor extends React.Component {

  constructor(props) {
    super(props)
  }

  state = {
    host: "",
    port: "",
    hostError: "",
    portError: "",
    streams: this.props.streams
  }

  blockKey = (e) => {
    e.preventDefault()
  }
  
  filterKey = (reg, e) => {
    if(!reg.test(e.key)) {
      e.preventDefault()
    }
  }
  
  handleHostChange = (value) => {
    this.setState({...this.state, host: value })
  }

  handlePortChange = (value) => {
    this.setState({...this.state, port: value })
  }

  addStream = () => {
    var valid = true
    var hostError = ""
    var portError = ""
    
    if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.state.host)) {  
      valid = false
      hostError = "Invalid IP"
    }
    if (this.state.port < 1024 || this.state.port > 65535) {
      valid = false
      portError = "Invalid port"
    }
    if (!valid) {
      this.setState({...this.state, hostError: hostError, portError: portError})
      return
    }
    
    var streams = this.state.streams
    var count = streams.length + 1
    
    var name = "UDPSTREAM" + count
    while (streams.some(str => str.name === name)) {
      count = count + 1
      name = "UDPSTREAM" + count
    }
    
    streams.push({ name: name, host: this.state.host, port: this.state.port })
    this.setState({...this.state, streams: streams, hostError: hostError, portError: portError,
                   host: "", port: "" },
                  () => this.props.onStreamsUpdated(this.state.streams))
  }

  editStream = (streamName, name, value) => {
    var streams = this.state.streams
    streams.forEach(stream => {
      if (stream.name === streamName) {
        stream[name] = value
      }
    })
    this.setState({...this.state, streams: streams },
                  () => this.props.onStreamsUpdated(this.state.streams))
  }

  deleteStream = (name) => {
    var streams = this.state.streams.filter(s => s.name !== name)

    this.setState({...this.state, streams: streams },
                  () => this.props.onStreamsUpdated(this.state.streams))
  }

  renderStreams = () => {
    return this.state.streams.map(stream => (
      <div className={theme['udp-row']} theme={theme}>
        <div className={theme['udp-row-input']} theme={theme}>
          <Input type="text" value={stream.host} label={ i18n.t("config_udp_host") }
                 className={styles.ipAddressInput} theme={styles}
                 readOnly={true}
                 onChange={this.editStream.bind(this, stream.name, "host")}
                 onKeyPress={this.blockKey} />
        </div>
        <div className={theme['udp-row-input']} theme={theme}>
          <Input type="text" value={stream.port} label={ i18n.t("config_udp_port") }
                 className={theme.udpPortInput} theme={theme}
                 readOnly={true}
                 onChange={this.editStream.bind(this, stream.name, "port")}
                 onKeyPress={this.blockKey} />
        </div>
        <div>
          <Button onClick={this.deleteStream.bind(this, stream.name)}
                  icon="delete"
                  className={styles.deleteButton} theme={styles}
                  accent floating mini />
        </div>
      </div>
    ))
  }

  render () {

    return (
      <I18n ns="translations">
        {
          (t, { i18n }) => (
            <section>
              <div>
                {/* <p>
                  <a href="/api/sample-udp" >{ t("sample_udp_download") }</a>
                  <span> { t("sample_udp_dl_text") }</span>
                </p> */}
                <div className={theme['udp-row']} theme={theme} >
                  <div className={theme['udp-row-input']} theme={theme}>
                    <Input type="text" label={ t("config_udp_host") } name="host"
                           value={this.state.host} hint="e.g. 192.168.0.10"
                           className={styles.ipAddressInput} theme={styles}
                           error={this.state.hostError}
                           onKeyPress={this.filterKey.bind(this, /[0-9\.]/)}
                           onChange={this.handleHostChange} />
                  </div>
                  <div className={theme['udp-row-input']} theme={theme}>
                    <Input type="text" label={ t("config_udp_port")} name="port"
                           pattern="[0-9]*"
                           className={theme.udpPortInput} theme={theme}
                           value={this.state.port} hint="e.g. 8888"
                           error={this.state.portError}
                           onKeyPress={this.filterKey.bind(this, /[0-9]/)}
                           onChange={this.handlePortChange} />
                  </div>
                  <div>
                    <Button icon="add" onClick={this.addStream} primary floating mini
                            disabled={this.state.host === "" || this.state.port === "" } />
                  </div>
                </div>
                { this.renderStreams() }
              </div>
            </section>
          )}
      </I18n>
    )
  }
}

export default UdpStreamsEditor
